<template>
	<div class="">
		<qy-top-nav :title="userId ? '业务员编辑' : '新增业务员'" />
		<van-form @submit="submit" label-width="60" style="margin-top: 12px; padding: 0 10px">
			<van-cell-group>
				<van-field v-model="phone" label="手机号" placeholder="" :rules="[{ required: true, message: '请输入手机号' }]" :disabled="userId ? true : false" />
			</van-cell-group>
			<van-cell-group>
				<van-field v-model="userName" label="姓名" placeholder="" :rules="[{ required: true, message: '请输入姓名' }]" />
			</van-cell-group>
			<!-- <div class="flex-between" style="background-color: #fff; padding: 10px 15px; font-size: 14px; margin: 12px 0; display: none">
				<span>类别：</span>
				<van-radio-group v-model="tag" direction="horizontal">
					<van-radio :name="tag.dictValue" v-for="tag in tagList">{{ tag.dictLabel }}</van-radio>
				</van-radio-group>
			</div> -->
			<div>
				<van-button type="warning" color="#ffad1a" block native-type="submit">保存</van-button>
			</div>
		</van-form>
	</div>
</template>
<script type="text/javascript">
export default {
	name: 'customerEdit',
	data() {
		return {
			userId: '',
			phone: '',
			userName: '',
			tagList: [],
			tag: 'C'
		};
	},

	created() {
		this.userId = this.getUrlParam('userId') || '';
		this.fetchDetail();
	},
	methods: {
		fetchDetail() {
			this.doPost(
				'/api/common/dict',
				{
					type: 'customer_type'
				},
				(rs) => {
					this.tagList = rs;
				}
			);
			if (this.userId) {
				this.doPost('/api/proxy/oper/getClient', { userId: this.userId }, (rs) => {
					if (rs.success) {
						this.phone = rs.data.phone;
						this.userName = rs.data.userName;
						this.tag = rs.data.tag;
					}
				});
			}
		},
		submit() {
			let url = this.userId ? '/api/proxy/oper/editClient' : '/api/proxy/oper/addClient';
			this.doPost(
				url,
				{
					userId: this.userId,
					phone: this.phone,
					tag: this.tag,
					o: 1,
					userName: this.userName
				},
				(rs) => {
					if (rs.success) {
						this.alert('保存成功', () => {
							this.navigateBack();
						});
					} else {
						this.alert(rs.msg);
					}
				}
			);
		}
	}
};
</script>
<style type="text/css"></style>
